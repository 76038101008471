export const USER_VENDOR = 1;
export const USER_GRSE_PURCHASE = 17;
export const USER_GRSE_HR = 18;
export const USER_GRSE_CONTRACT = 20;
export const USER_ADMIN = 5;
export const USER_SUPER_ADMIN = 6;
export const USER_PPNC_DEPARTMENT = 14;
export const ASSIGNER = 1;
export const STAFF = 2;
export const NODALOFFICER = 1;
export const GENERALUSER = 2;

export const DEPT_IT = 1;
export const DEPT_DESIGN = 2;
export const DEPT_QAP = 3;
export const DEPT_FI = 15;
export const DEPT_STORE = 5;
export const DEPT_RIC = 16;
